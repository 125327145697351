import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-preloader',
    templateUrl: './preloader.component.html',
    styleUrls: ['./preloader.component.scss'],
    standalone: true
})
export class PreloaderComponent {

  @Input('color') color = '#fff';

  constructor() { }
}
